<template>
  <div class="quantityRate">
    <div class="content">
      <div class="content_1" v-for="(item,index) in list" :key="index">
        <div class="sty">
          <div
              :class="item.name === '计划任务数' ? 'left'
              : item.name === '已完成任务数' ? 'left2'
              : item.name === '加工中任务数' ? 'left3'
              : item.name === '报工次数' ? 'left4' : 'left5'"></div>
          <div class="right">
            <div class="right_left">
              <img v-if="item.name === '计划任务数'" :src="src" width="60%" height="60%">
              <img v-if="item.name === '已完成任务数'" :src="src2" width="60%" height="60%">
              <img v-if="item.name === '加工中任务数'" :src="src3" width="60%" height="60%">
              <img v-if="item.name === '报工次数'" :src="src4" width="60%" height="60%">
              <img v-if="item.name === '不良率'" :src="src5" width="60%" height="60%">
            </div>
            <div class="right_right">
              <div class="right_right_1">{{ item.name }}</div>
              <div class="right_right_2">{{ item.assignment }}</div>
              <div class="right_right_3">产品数： <span style="color: #45ce45">{{ item.products }}</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "quantityRate",
  data() {
    return {
      list: [
        {
          name: '计划任务数',
          assignment: window.productionSituation.top1,
          products: window.productionSituation.top1_count,
        },
        {
          name: '已完成任务数',
          assignment: window.productionSituation.top2,
          products: window.productionSituation.top2_count,
        },
        {
          name: '加工中任务数',
          assignment: window.productionSituation.top3,
          products: window.productionSituation.top3_count,
        },
        {
          name: '报工次数',
          assignment: window.productionSituation.top4,
          products: window.productionSituation.top4_count,
        },
        {
          name: '不良率',
          assignment: window.productionSituation.top5 + '%',
          products: window.productionSituation.top5_count,
        },
      ],
      src: require('@/assets/image/production/plan.svg'),
      src2: require('@/assets/image/production/completed.svg'),
      src3: require('@/assets/image/production/process.svg'),
      src4: require('@/assets/image/production/workReport.svg'),
      src5: require('@/assets/image/production/bad.svg'),
    }
  },
}
</script>

<style scoped lang="scss">
.quantityRate {
  width: 100%;
  height: 100%;

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .content_1 {
      flex: 1;
      height: 100%;
      width: 95%;
      margin: 0 1%;
      display: flex;
      justify-content: center;
      align-items: center;

      .sty {
        width: 100%;
        height: 90%;
        display: flex;

        .left {
          width: 2%;
          height: 100%;
          background: rgb(251, 98, 96);
        }

        .left2 {
          width: 2%;
          height: 100%;
          background: rgb(0, 153, 255);
        }

        .left3 {
          width: 2%;
          height: 100%;
          background: rgb(129, 103, 245);
        }

        .left4 {
          width: 2%;
          height: 100%;
          background: rgb(81, 211, 81);
        }

        .left5 {
          width: 2%;
          height: 100%;
          background: rgb(255, 122, 140);
        }

        .right {
          width: 95%;
          height: 100%;
          display: flex;
          background: #fff;
          border: 1px solid rgba(222, 224, 225, 0.75);
          //box-shadow: 0 1px 3px 1px #b0acac;

          .right_left {
            width: 30%;
            height: 100%;
          }

          .right_right {
            width: 40%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            .right_right_1 {
              font-size: 1.2rem;
              color: gray;
              margin-top: 12%;
            }

            .right_right_2 {
              font-size: 1.5rem;
              font-weight: bold;
              color: #626161;
              margin-top: 12%;
            }

            .right_right_3 {
              font-size: 1rem;
              color: gray;
              display: flex;
              height: 20%;
              align-items: center;
              margin-top: 8%;
            }
          }
        }
      }
    }
  }
}
</style>
