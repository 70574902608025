<template>
  <div class="processProgress">
    <div class="top">
      <div class="title">
        <div class="circle">
          <div class="circle_1"></div>
        </div>
        工序状态
      </div>
    </div>
    <div class="content">
      <div class="content_sco">
        <vue-seamless-scroll :data="list" class="wrap" :class-option="defaultOption">
          <div class="content_sco_1">
            <div class="content_1" v-for="(item, index) in list" :key="index">
              <div class="borderSty">
                <div class="content_top">
                  <div class="left">
                    <div class="left_1">{{ item.status }}</div>
                    <div class="left_2">
                      <div class="left_2_1">{{ item.num }}</div>
                      <div class="left_2_2">{{ item.name }}</div>
                    </div>
                  </div>
                  <div class="right">
                    <div class="right_1">计划数：<span style="color: #fff">{{ item.planNum }}</span></div>
                    <div class="right_1">良品数：<span style="color: #45ce45">{{ item.goodNum }}</span></div>
                    <div class="right_1">不良品：<span style="color: #FFA500FF">{{ item.noGoodNum }}</span></div>
                  </div>
                </div>
                <div class="bottom">
                  <div class="bottom_1">进度</div>
                  <div class="bottom_2">
                    <el-progress :percentage="item.progress"></el-progress>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </vue-seamless-scroll>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "processProgress",
  computed: {
    defaultOption() {
      return {
        step: 0.3, // 数值越大速度滚动越快
        // limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    },
  },
  data() {
    return {
      list: window.dataBoard.inCenter
      // list:[
      //   {
      //     status: '水洗',
      //     num: 3,
      //     name: '生产任务数',
      //     planNum: '2332',
      //     goodNum: '2332',
      //     noGoodNum: '2332',
      //     progress: '0',
      //   },
      //   {
      //     status: '水洗',
      //     num: 3,
      //     name: '生产任务数',
      //     planNum: '2332',
      //     goodNum: '2332',
      //     noGoodNum: '2332',
      //     progress: '0',
      //   },
      //   {
      //     status: '水洗',
      //     num: 3,
      //     name: '生产任务数',
      //     planNum: '2332',
      //     goodNum: '2332',
      //     noGoodNum: '2332',
      //     progress: '0',
      //   },
      //   {
      //     status: '水洗',
      //     num: 3,
      //     name: '生产任务数',
      //     planNum: '2332',
      //     goodNum: '2332',
      //     noGoodNum: '2332',
      //     progress: '0',
      //   },
      //   {
      //     status: '水洗',
      //     num: 3,
      //     name: '生产任务数',
      //     planNum: '2332',
      //     goodNum: '2332',
      //     noGoodNum: '2332',
      //     progress: '0',
      //   },
      //   {
      //     status: '水洗',
      //     num: 3,
      //     name: '生产任务数',
      //     planNum: '2332',
      //     goodNum: '2332',
      //     noGoodNum: '2332',
      //     progress: '0',
      //   },
      //   {
      //     status: '水洗',
      //     num: 3,
      //     name: '生产任务数',
      //     planNum: '2332',
      //     goodNum: '2332',
      //     noGoodNum: '2332',
      //     progress: '0',
      //   },
      //   {
      //     status: '水洗',
      //     num: 3,
      //     name: '生产任务数',
      //     planNum: '2332',
      //     goodNum: '2332',
      //     noGoodNum: '2332',
      //     progress: '0',
      //   },
      //   {
      //     status: '水洗',
      //     num: 3,
      //     name: '生产任务数',
      //     planNum: '2332',
      //     goodNum: '2332',
      //     noGoodNum: '2332',
      //     progress: '0',
      //   },
      // ],
    }
  },
}
</script>

<style scoped lang="scss">
.processProgress {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .top {
    width: 100%;
    height: 10%;
    margin-top: 2%;
    margin-left: 2%;

    .title {
      width: 20%;
      height: 70%;
      border: 1px solid rgba(2, 166, 181, 0.3);
      color: rgba(2, 166, 181, 1);
      border-radius: 0 50px 50px 0;
      font-weight: bold;
      border-left-style: none;
      display: flex;
      align-items: center;
      justify-content: center;

      .circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 1px solid rgba(2, 166, 181, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 5%;

        .circle_1 {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: rgba(2, 166, 181, 1);
          position: absolute;
        }
      }
    }
  }

  .content {
    width: 99%;
    height: 85%;

    .content_sco {
      width: 100%;
      height: 100%;

      .wrap {
        margin: 0 auto;
        width: 100%;
        height: 320px;
        overflow: hidden;

        .content_sco_1 {
          width: 100%;
          height: 100%;
          display: flex;
          flex-wrap: wrap;
        }
      }

      //animation: run 10s linear infinite;
      //animation: 10s rowup linear infinite normal;

      .content_1 {
        width: 26%;
        height: 130px;
        border: 1px solid #d1f6f6;
        margin-left: 5%;
        margin-bottom: 2%;
        border-radius: 5px;

        //overflow: hidden;

        .borderSty {
          width: 100%;
          height: 100%;

          .content_top {
            width: 100%;
            height: 70%;
            display: flex;

            .left {
              width: 40%;
              height: 100%;
              position: relative;

              .left_1 {
                width: 80%;
                height: 20%;
                position: absolute;
                z-index: 2;
                border-bottom-right-radius: 15px;
                background: #0ac6d7;
                color: #fff;
                font-size: 0.5rem;
              }

              .left_2 {
                width: 100%;
                height: 100%;
                position: absolute;
                z-index: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;

                .left_2_1 {
                  color: #fff;
                  font-size: 1.2rem;
                  font-weight: bold;
                  padding-bottom: 20%;
                }

                .left_2_2 {
                  color: #fff;
                  font-size: 0.6rem;
                  padding-bottom: 20%;
                }

              }
            }

            .right {
              width: 60%;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              .right_1 {
                color: #fff;
                font-size: 0.5rem;
                padding-bottom: 12%;
                padding-top: 2%;
              }
            }
          }

          .bottom {
            width: 100%;
            height: 20%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .bottom_1 {
              color: #fff;
              font-size: 0.5rem;
              width: 20%;
            }

            .bottom_2 {
              width: 75%;
            }
          }
        }
      }

    }


  }

  @keyframes run {
    0% {
      top: 0;
    }
    100% {
      top: -500px;
    }
  }
  @keyframes rowup {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    100% {
      -webkit-transform: translate3d(0, -200px, 0);
      transform: translate3d(0, -200px, 0);
    }
  }
}
</style>
