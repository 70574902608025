<template>
  <div class="workReport">
    <div class="top">
      <div class="title">
        <div class="circle">
          <div class="circle_1"></div>
        </div>
        报工情况
      </div>
    </div>
    <div class="bottom">
      <div id="echartsLine" style="width: 100%;height: 100%"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "workReport",
  data() {
    return {
      colorList: [
        ['#e10d33', '#e10d33', '#e10d33'],
        ['#f54e1b', '#f54e1b'],
      ],
      option: {
        color: ['#e10d33','#f54e1b'],
        title: {},
        legend: {
          bottom: '5%',
          itemHeight: 8,
          itemWidth: 8,
          textStyle: {
            fontSize: 12,
            color: "#fff",
          },
        },
        tooltip: {
          trigger: 'axis',
          valueFormatter: (value) => (value ? value + '个' : '-'),
        },
        grid: {
          left: '5%',
          right: '10%',
          bottom: '20%',
          top: '20%',
          containLabel: true,
        },
        dataset: {
          dimensions: [],
          source: [],
        },
        xAxis: {
          name: '日期',
          type: 'category',
        },
        yAxis: {
          name: '单位',
          minInterval: 2,
        },
        series: [],
      },
    }
  },
  methods: {
    getOption(){
      let data = [
        // {
        //   month: '12-01',
        //   name: '报工数量',
        //   count: 1.2,
        // },
        // {
        //   month: '12-02',
        //   name: '不良品',
        //   count: 3,
        // },
        // {
        //   month: '12-02',
        //   name: '报工数量',
        //   count: 15,
        // },
        // {
        //   month: '12-03',
        //   name: '报工数量',
        //   count: 1.5,
        // },
        // {
        //   month: '12-04',
        //   name: '报工数量',
        //   count: 1.2,
        // },
        // {
        //   month: '12-05',
        //   name: '报工数量',
        //   count: 8,
        // },{
        //   month: '12-06',
        //   name: '不良品',
        //   count: 10,
        // },{
        //   month: '12-07',
        //   name: '报工数量',
        //   count: 12,
        // },{
        //   month: '12-08',
        //   name: '不良品',
        //   count: 15,
        // },
      ];
      data = window.dataBoard.rightCenter
      let dataset1 = {
        dimensions: [],
        source: [],
      };
      // let source = [];
      if (data !== null) {
        let names = [];
        let months = [];
        let list = []
        let obj = {};
        data.forEach((item) => {
          let name = item.name;
          let month = item.month;
          if (names.indexOf(name) === -1) {
            names.push(name);
          }
          if(months.indexOf(month) === -1) {
            months.push(month)
          }
        });
        for (let i = 0;i<months.length;i++) {
          obj = {
            'month': months[i]
          }
          names.forEach(item => {
            obj[item] = 0
          })
          list.push(obj)
        }
        list.forEach((listItem) => {
          data.forEach((item) => {
            if (listItem.month === item.month) {
              Object.keys(listItem).forEach((key) => {
                if (key == item.name) {
                  listItem[key] = item.count;
                }
              });
            }
          });
        });
        dataset1.dimensions = ['month', ...names];
        dataset1.source = [...list];
        this.option.dataset = dataset1;
        let tempSeries1 = this.getMultiBars(names, this.colorList);
        this.option.series = [...tempSeries1];
      } else {
        this.option.dataset = [];
      }
    },
    getMultiBars(legend, colorList) {
      let size = colorList.length;
      let series = [];
      for (let i = 0; i < legend.length; i++) {
        let colors = [];
        let tempColor = colorList[i % size];
        for (let j = 0; j < tempColor.length; j++) {
          colors.push({
            offset: j / (tempColor.length - 1),
            color: tempColor[j],
          });
        }
        series.push({
          type: 'line',
          name: legend[i],
        });
      }
      return series;
    },
    getEcharts(){
      this.$nextTick(() => {
        let myChart = this.echarts.init(document.getElementById('echartsLine'));
        myChart.setOption(this.option);
        // 图表自适应
        window.addEventListener('resize', () => {
          setTimeout(() => {
            myChart.resize();
          },100)
        })
      })
    },
  },
  created() {
    this.getOption();
    this.getEcharts()
  },

}
</script>

<style scoped lang="scss">
.workReport {
  width: 100%;
  height: 100%;

  .top {
    width: 100%;
    height: 10%;
    margin-top: 2%;
    margin-left: 2%;

    .title {
      width: 20%;
      height: 100%;
      border: 1px solid rgba(2, 166, 181, 0.3);
      color: rgba(2, 166, 181, 1);
      border-radius: 0 50px 50px 0;
      font-weight: bold;
      border-left-style: none;
      display: flex;
      align-items: center;
      justify-content: center;

      .circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 1px solid rgba(2, 166, 181, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 5%;

        .circle_1 {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: rgba(2, 166, 181, 1);
          position: absolute;
        }
      }
    }
  }
  .bottom {
    width: 100%;
    height: 85%;
  }
}
</style>
