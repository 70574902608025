<template>
  <div class="productProductionTrend">
    <div class="top">
      <div class="top_title">产品生产趋势</div>
    </div>
    <div class="bottom">
      <div id="productProductionTrend" style="width: 100%;height: 100%"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "productProductionTrend",
  data() {
    return {
      colorList: [
        ['rgb(178,224,255)', 'rgb(178,224,255)'],
        ['rgb(255,215,220)', 'rgb(255,215,220)'],
      ],
      option: {
        color: ['rgb(0,153,255)','rgb(255,122,140)'],
        title: {},
        legend: {
          icon: 'rect',
          bottom: '5%',
          itemHeight: 8,
          itemWidth: 12,
          textStyle: {
            fontSize: 12,
            // color: "#fff",
          },
        },
        tooltip: {
          trigger: 'axis',
          valueFormatter: (value) => (value ? value + '' : '-'),
        },
        grid: {
          left: '5%',
          right: '10%',
          bottom: '20%',
          top: '20%',
          containLabel: true,
        },
        dataset: {
          dimensions: [],
          source: [],
        },
        xAxis: {
          name: '日期',
          type: 'category',
        },
        yAxis: {
          name: '单位',
          minInterval: 2,
        },
        series: [],
      },
    }
  },
  methods: {
    getOption(){
      let data = [
        // {
        //   month: '12-01',
        //   name: '良品数',
        //   count: 1.2,
        // },
        // {
        //   month: '12-02',
        //   name: '生产产品',
        //   count: 3,
        // },
        // {
        //   month: '12-02',
        //   name: '良品数',
        //   count: 15,
        // },
        // {
        //   month: '12-03',
        //   name: '生产产品',
        //   count: 1.5,
        // },
        // {
        //   month: '12-04',
        //   name: '良品数',
        //   count: 1.2,
        // },
        // {
        //   month: '12-05',
        //   name: '生产产品',
        //   count: 8,
        // },{
        //   month: '12-06',
        //   name: '良品数',
        //   count: 10,
        // },{
        //   month: '12-07',
        //   name: '良品数',
        //   count: 12,
        // },{
        //   month: '12-08',
        //   name: '生产产品',
        //   count: 15,
        // },
      ];

      data = window.productionSituation.bottom
      let dataset1 = {
        dimensions: [],
        source: [],
      };
      // let source = [];
      if (data !== null) {
        let names = [];
        let months = [];
        let list = []
        let obj = {};
        data.forEach((item) => {
          let name = item.name;
          let month = item.month;
          if (names.indexOf(name) === -1) {
            names.push(name);
          }
          if(months.indexOf(month) === -1) {
            months.push(month)
          }
        });
        for (let i = 0;i<months.length;i++) {
          obj = {
            'month': months[i]
          }
          names.forEach(item => {
            obj[item] = 0
          })
          list.push(obj)
        }
        list.forEach((listItem) => {
          data.forEach((item) => {
            if (listItem.month === item.month) {
              Object.keys(listItem).forEach((key) => {
                if (key == item.name) {
                  listItem[key] = item.count;
                }
              });
            }
          });
        });
        dataset1.dimensions = ['month', ...names];
        dataset1.source = [...list];
        this.option.dataset = dataset1;
        let tempSeries1 = this.getMultiBars(names, this.colorList);
        this.option.series = [...tempSeries1];
      } else {
        this.option.dataset = [];
      }
    },
    getMultiBars(legend, colorList) {
      let size = colorList.length;
      let series = [];
      for (let i = 0; i < legend.length; i++) {
        let colors = [];
        let tempColor = colorList[i % size];
        for (let j = 0; j < tempColor.length; j++) {
          colors.push({
            offset: j / (tempColor.length - 1),
            color: tempColor[j],
          });
        }
        series.push({
          type: 'line',
          name: legend[i],
          areaStyle: {
            normal: {
              //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
              color: new this.echarts.graphic.LinearGradient(0, 0, 0, 1, colors),
            },
          },
        });
      }
      return series;
    },
    getEcharts(){
      this.$nextTick(() => {
        let myChart = this.echarts.init(document.getElementById('productProductionTrend'));
        myChart.setOption(this.option);
      })
    },
  },
  created() {
    this.getOption();
    this.getEcharts()
  },
}
</script>

<style scoped lang="scss">
.productProductionTrend {
  width: 100%;
  height: 100%;

  .top {
    width: 100%;
    height: 15%;
    display: flex;
    align-items: center;

    .top_title {
      width: 20%;
      height: 100%;
      margin-left: 5%;
      color: #78797a;
      font-weight: bold;
      display: flex;
      align-items: center;
      font-size: 1rem;
    }
  }

  .bottom {
    width: 100%;
    height: 85%;
  }
}
</style>
