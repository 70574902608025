<template>
  <div class="workOrderStatus">
    <div class="top">
      <div class="title">
        <div class="circle">
          <div class="circle_1"></div>
        </div>
        工单状态
      </div>
    </div>
    <div class="bottom">
      <div class="bottom_1" v-for="(item,index) in list" :key="index">
        <div class="bottom_2">
          <div class="bottom_top">
            <div class="bottom_top_1">
              <div
                  :class="item.status === '待确认' ? 'bottom_top_2' : item.status === '已确认' ? 'bottom_top_22' : 'bottom_top_23'">
                <div class="bottom_top_3"></div>
              </div>
            </div>
          </div>
          <div class="bottom_bottom">
            <div class="bottom_bottom_1">{{ item.num }}</div>
            <div class="bottom_bottom_2">{{ item.status }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "workOrderStatus",
  data() {
    return {
      list: [
        {
          status: '待确认',
          num: window.dataBoard.rightTop1,
        },
        {
          status: '已确认',
          num: window.dataBoard.rightTop2,
        },
        {
          status: '已完成',
          num: window.dataBoard.rightTop3,
        },
      ],
    }
  },
}
</script>

<style scoped lang="scss">
.workOrderStatus {
  width: 100%;
  height: 100%;

  .top {
    width: 100%;
    height: 10%;
    margin-top: 2%;
    margin-left: 2%;

    .title {
      width: 20%;
      height: 100%;
      border: 1px solid rgba(2, 166, 181, 0.3);
      color: rgba(2, 166, 181, 1);
      border-radius: 0 50px 50px 0;
      font-weight: bold;
      border-left-style: none;
      display: flex;
      align-items: center;
      justify-content: center;

      .circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 1px solid rgba(2, 166, 181, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 5%;

        .circle_1 {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: rgba(2, 166, 181, 1);
          position: absolute;
        }
      }
    }
  }

  .bottom {
    width: 100%;
    height: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3%;


    .bottom_1 {
      width: 30%;
      height: 100%;

      .bottom_2 {
        width: 100%;
        height: 95%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .bottom_top {
          width: 100%;
          height: 50%;
          display: flex;
          justify-content: center;
          align-items: center;


          .bottom_top_1 {
            width: 50%;
            height: 100%;
            border-radius: 50%;
            border: 4px dashed rgba(136, 135, 135, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            animation: circleRotate 12s linear infinite reverse;

            .bottom_top_2 {
              width: 85%;
              height: 85%;
              background: deeppink;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              animation: circleRotate 12s linear infinite;

              .bottom_top_3 {
                width: 50%;
                height: 50%;
                background: url("@/assets/image/wujiaoxing.svg") no-repeat 100% 100%;
              }
            }

            .bottom_top_22 {
              width: 85%;
              height: 85%;
              background: #9322ef;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              animation: circleRotate 12s linear infinite;

              .bottom_top_3 {
                width: 50%;
                height: 50%;
                background: url("@/assets/image/wujiaoxing.svg") no-repeat 100% 100%;
              }
            }

            .bottom_top_23 {
              width: 85%;
              height: 85%;
              background: #0fd7f1;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              animation: circleRotate 12s linear infinite;

              .bottom_top_3 {
                width: 50%;
                height: 50%;
                background: url("@/assets/image/wujiaoxing.svg") no-repeat 100% 100%;
              }
            }
          }
        }

        .bottom_bottom {
          width: 100%;
          height: 50%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;

          .bottom_bottom_1 {
            color: rgb(86, 135, 146);
            font-size: 1.5rem;
            font-weight: bold;
          }

          .bottom_bottom_2 {
            color: rgb(4, 133, 170);
            font-size: 1.5rem;
            font-weight: bold;
          }
        }

      }

      @keyframes circleRotate {
        0% {
          transform: rotate3d(0, 0, 1, 0deg);
        }
        50% {
          transform: rotate3d(0, 0, 1, 180deg);
        }
        100% {
          transform: rotate3d(0, 0, 1, 360deg);
        }
      }
    }
  }
}
</style>
