<template>
<div class="totalWorkOrder">
  <div class="content">
    <div class="left">
      <div class="top">
        <div class="title">
          <div class="circle">
            <div class="circle_1"></div>
          </div>
          总工单
        </div>
      </div>
      <div class="bottom">
        <div class="bottom_1">
          <div class="box-item">
            <li
                :class="{ 'number-item': !isNaN(item), 'mark-item': isNaN(item) }"
                v-for="(item, index) in orderNum"
                :key="index"
            >
              <span v-if="!isNaN(item)"> <i ref="numberItem">0123456789</i>
              </span>
              <span class="comma" v-else>{{ item }}</span>
            </li>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "totalWorkOrder",
  props: {
    // 显示的数字
    number: {
      type: Number,
    }, // 显示的长度
    /*length: {
      type: Number,
    },*/
  },
  data() {
    return {
      orderNum: ['0', '0', '0', '0'], // 默认订单总数
      orderNum2: ['0', '0', '0'], // 默认订单总数
      length: 4,
    }
  },
  mounted(){
    setTimeout(() => {
      this.toOrderNum(window.dataBoard.top1); // 这里输入数字即可调用
    }, 500);
  },
  watch: {
    number: {
      handler(val) {
        this.toOrderNum(val);
      },
      deep: true,
    },
  },
  methods: {
    // 处理总订单数字
    // 设置文字滚动
    setNumberTransform() {
      const numberItems = this.$refs.numberItem; // 拿到数字的ref，计算元素数量 // eslint-disable-next-line no-restricted-globals
      const numberArr = this.orderNum.filter((item) => !isNaN(item));
      console.log(numberItems.length, numberArr); // 结合CSS 对数字字符进行滚动,显示数量
      for (let index = 0; index < numberItems.length; index += 1) {
        const elem = numberItems[index];
        elem.style.transform = `translate(-50%, -${numberArr[index] * 10}%)`;
      }
    }, // 处理总数字
    toOrderNum(num) {
      const numtext = num.toString();
      if (this.length) {
        if (numtext.length < this.length) {
          const numlist = `0${numtext}`; // 如未满固定数，添加"0"补位
          this.toOrderNum(numlist); // 递归添加"0"补位
        } else if (numtext.length === num.length) {
          this.orderNum = numtext.split(""); // 将其便变成数据，渲染至滚动数组
        }
      } else {
        this.orderNum = numtext.split("");
      } // 数字中加入逗号 // const length = numtext.length / 3; // let count = ''; // for (let i = 0; i < length; i += 1) { //   if (i === 0) { //     count += `${numtext.slice(i, i + 3)},`; //     console.log(count); //   } else if (i === length - 1) { //     count += numtext.slice(i * 3, i * 3 + 3); //     console.log(count); //   } else { //     count += `${numtext.slice(i * 3, i * 3 + 3)},`; //     console.log(count); //   } // } // this.orderNum = count.split('');
      this.setNumberTransform();
    },

  },
}
</script>

<style scoped lang="scss">
.totalWorkOrder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    width: 95%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;

    .left {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;

      .top {
        width: 100%;
        height: 15%;
        margin-top: 2%;
        margin-left: 2%;

        .title {
          width: 65%;
          height: 100%;
          border: 1px solid rgba(2, 166, 181, 0.3);
          color: rgba(2, 166, 181, 1);
          border-radius: 0 50px 50px 0;
          font-weight: bold;
          border-left-style: none;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-left: 10%;

          .circle {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            border: 1px solid rgba(2, 166, 181, 1);
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 5%;

            .circle_1 {
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background: rgba(2, 166, 181, 1);
              position: absolute;
            }
          }
        }
      }

      .bottom {
        width: 100%;
        height: 80%;
        .bottom_1 {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          .box-item {
            position: relative;
            height: 80px;
            font-size: 2.5rem;
            font-family: AzonixRegular;
            color: #021c25;
            line-height: 41px;
            text-align: center;
            list-style: none;
            writing-mode: vertical-lr;
            text-orientation: upright;
          }
          /* 默认逗号设置 */
          .mark-item {
            width: 28px;
            height: 34px;
            position: relative;
            /* 背景图片 */
            background: #02a6b5;
            //background: url('../../assets/img/shuziluang@2x.png') no-repeat center center;
            //background-size: 100% 100%;
            list-style: none;
            margin-right: 1px;
            & > span {
              position: absolute;
              width: 100%;
              height: 100%;
              bottom: 2px;
              left: 20%;
              font-size: 20px;
              writing-mode: vertical-rl;
              text-orientation: upright;
            }
          }
          /*滚动数字设置*/
          .number-item {
            width: 35px;
            height: 60px;
            /* 背景图片 */
            border: 1px solid rgb(18,172,191);
            border-radius: 15%;
            color: #fff;
            background: linear-gradient(to bottom, rgba(4, 54, 72, 0.89), rgba(4, 54, 72, 0.39));
            // background: #ccc;
            list-style: none;
            margin-right: 8%;
            & > span {
              position: relative;
              display: inline-block;
              margin-right: 10px;
              width: 100%;
              height: 100%;
              writing-mode: vertical-rl;
              text-orientation: upright;
              overflow: hidden;
              display: flex;
              align-items: center;
              justify-content: center;
              & > i {
                font-style: normal;
                position: absolute;
                top: 8px;
                left: 50%;
                transform: translate(-50%, 0);
                transition: transform 1s ease-in-out;
                letter-spacing: 10px;
              }
            }
          }
          .number-item:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
</style>
