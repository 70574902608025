<template>
  <div class="equipmentStatusPie">
    <div class="top">
      <div class="top_title">设备状态</div>
    </div>
    <div class="bottom">
      <div id="equipmentStatusPie" style="width: 100%;height: 100%"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "equipmentStatusPie",
  data() {
    return {
      list: [],
      option: {
        color: ['#ff7a8c', '#52c1f5', '#8167f5', '#ffa94c'],
        tooltip: {
          trigger: 'item',
          formatter: (param) => {
            let percent = 0;
            let total = 0;
            for (let i = 0; i < this.list.length; i++) {
              total += this.list[i].value;
            }
            if (total !== 0) {
              percent = ((param.value / total) * 100).toFixed(2);
            } else {
              percent = 0;
            }
            if (param.name) {
              return param.name + ':' + '(' + percent + '%)';
              // return percent + '%';
            }
          },
        },
        legend: {
          type: 'scroll',
          orient: 'vertical',
          top: 'center',
          right: '25%',
          icon: 'circle',
          itemWidth: 8,
          itemHeight: 8,
          itemGap: 10,
          // width: '200rem',
          padding: 15,
          formatter: (name) => {
            let total = 0;
            for (let i = 0; i < this.list.length; i++) {
              total += this.list[i].value;
            }

            for (let i = 0; i < this.list.length; i++) {
              let dpercent = ((this.list[i].value / total) * 100).toFixed(2) + '%';
              if (this.list[i].name === name) {
                //可以显示名称和值和百分比
                return name + '\r       ' + dpercent;
              }
            }
          },
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '70%'],
            center: ['25%', '50%'], //设置饼图左跟上的位置
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              // 设置圈内提示
              label: {
                show: true,
                fontSize: 20,
                fontWeight: 'bold',
                formatter: '{b}\n {d}%'
              }
            },
            labelLine: {
              show: false
            },
            data: []
          }
        ]
      },
    }
  },
  methods: {
    getPie() {
      let data = [
        // { value: 25, name: '故障' },
        // { value: 25, name: '运行' },
        // { value: 25, name: '检修' },
        // { value: 25, name: '关机' },
      ];
      data = window.productionSituation.center1
      let arr = [];
      data.forEach(item => {
        let obj = {
          name: item.name,
          value: item.value,
        };
        arr.push(obj)
      })
      this.list = data;
      this.option.series[0].data = [...arr]
    },
    getEcharts() {
      this.$nextTick(() => {
        let myChart = this.echarts.init(document.getElementById('equipmentStatusPie'));
        myChart.setOption(this.option);
      })
    },
  },
  created() {
    this.getPie()
    this.getEcharts()
  },
}
</script>

<style scoped lang="scss">
.equipmentStatusPie {
  width: 100%;
  height: 100%;

  .top {
    width: 100%;
    height: 15%;
    display: flex;
    align-items: center;

    .top_title {
      width: 20%;
      height: 100%;
      margin-left: 5%;
      color: #78797a;
      font-weight: bold;
      display: flex;
      align-items: center;
      font-size: 1rem;
    }
  }

  .bottom {
    width: 100%;
    height: 85%;
  }
}
</style>
