import VueRouter from "vue-router"
import DataBoard from "@/views/data-board/index.vue";
import ProductionSituation from "@/views/production-situation/index.vue";

const router = new VueRouter({
    routes: [
        //配置路由的路径
        {
            path: '/',
            component: DataBoard
        },
        {
            path: '/ProductionSituation',
            component: ProductionSituation
        }
    ]
})
export default router;
