<template>
  <div class="rejectsAnalyse">
    <div class="top">
      <div class="title">
        <div class="circle">
          <div class="circle_1"></div>
        </div>
        不良品分析
      </div>
    </div>
    <div class="bottom">
      <div id="rejectPie" style="width: 100%;height: 100%"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "rejectsAnalyse",
  data() {
    return {
      option: {
        color: ['#009dff', '#3ada15'],
        title: {},
        tooltip: {
          trigger: 'item',
          formatter: '{b}: {c}件 ({d}%)' //自定义显示格式(b:name, c:value, d:百分比)
        },
        legend: {
          icon: 'circle',
          left: 'center',
          bottom: '5%',
          itemHeight: 6,
          itemWidth: 6,
          textStyle: {
            fontSize: 12,
            color: "#fff",
          },
        },
        series: [
          {
            type: 'pie',
            radius: '50%',
            label: {
              color: '#fff',
            },
            data: [],
          }
        ]
      },
    }
  },
  methods: {
    getPie() {
      let data = [
        // { value: 735, name: '加工不良' },
        // { value: 1048, name: '来料不良' },
      ];
      data = window.dataBoard.rightBottom;
      let arr = [];
      data.forEach(item => {
        let obj = {
          name: item.name,
          value: item.value,
        };
        arr.push(obj)
      })
      this.option.series[0].data = [...arr]
    },
    getEcharts() {
      this.$nextTick(() => {
        let myChart = this.echarts.init(document.getElementById('rejectPie'));
        myChart.setOption(this.option);
        // 图表自适应
        window.addEventListener('resize', () => {
          setTimeout(() => {
            myChart.resize();
          }, 100)
        })
      })
    },
  },
  created() {
    this.getPie()
    this.getEcharts()
  },
}
</script>

<style scoped lang="scss">
.rejectsAnalyse {
  width: 100%;
  height: 100%;

  .top {
    width: 100%;
    height: 10%;
    margin-top: 2%;
    margin-left: 2%;

    .title {
      width: 20%;
      height: 100%;
      border: 1px solid rgba(2, 166, 181, 0.3);
      color: rgba(2, 166, 181, 1);
      border-radius: 0 50px 50px 0;
      font-weight: bold;
      border-left-style: none;
      display: flex;
      align-items: center;
      justify-content: center;

      .circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 1px solid rgba(2, 166, 181, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 5%;

        .circle_1 {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: rgba(2, 166, 181, 1);
          position: absolute;
        }
      }
    }
  }

  .bottom {
    width: 100%;
    height: 85%;
  }
}
</style>
