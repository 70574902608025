<template>
  <div class="goodProducts">
    <div class="top">
      <div class="top_title">产品良品情况</div>
    </div>
    <div class="bottom">
      <div class="bottom_top">
        <div class="bottom_1">
          <div class="bottom_1_top">
            <img src="../../../assets/image/production/good_fill.png" width="100%" height="100%">
          </div>
        </div>
        <div class="bottom_1">
          <div class="bottom_1_top">
            <img src="../../../assets/image/production/nogood.png" width="100%" height="100%">
          </div>
        </div>
      </div>
      <div class="bottom_center">
        <div class="bottom_1">
          <el-progress :percentage="percentage" stroke-width="20" :show-text="false" color="#52C1F5FF"></el-progress>
        </div>
      </div>
      <div class="bottom_bottom">
        <div class="bottom_1" v-for="(item, index) in lists" :key="index">
          <div class="bottom_1_top">{{ item.name }}</div>
          <div :class="item.name === '良品占比' ? 'bottom_1_bottom' : 'bottom_1_bottom_no'">{{ item.num }}%</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "goodProducts",
  data() {
    return {
      percentage: '',
      lists: [
        {
          name: '良品占比',
          num: window.productionSituation.center3_left,
        },
        {
          name: '不良品占比',
          num: window.productionSituation.center3_right,
        },
      ],
    }
  },
  created() {
    this.percentage = this.lists[0].num;
  }
}
</script>

<style scoped lang="scss">
.goodProducts {
  width: 100%;
  height: 100%;

  .top {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;

    .top_title {
      width: 20%;
      height: 100%;
      margin-left: 5%;
      color: #78797a;
      font-weight: bold;
      display: flex;
      align-items: center;
      font-size: 1rem;
    }
  }

  .bottom {
    width: 100%;
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .bottom_top {
      width: 80%;
      height: 20%;
      display: flex;
      justify-content: space-between;
      align-items: center;


      .bottom_1 {
        width: 10%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .bottom_1_top {
          width: 100%;
          height: 100%;
        }
      }
    }

    .bottom_center {
      width: 80%;
      height: 25%;
      margin-top: 5%;

      .bottom_1 {
        width: 100%;

        ::v-deep .el-progress-bar__outer {
          background-color: rgb(254, 192, 61) !important;
        }
      }


    }

    .bottom_bottom {
      width: 90%;
      height: 15%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .bottom_1 {
        width: 20%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .bottom_1_top {
          color: #a9aaab;
        }

        .bottom_1_bottom {
          color: rgb(82, 193, 245);
          font-size: 1.5rem;
          font-weight: bold;
        }

        .bottom_1_bottom_no {
          color: #FEC03DFF;
          font-size: 1.5rem;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
