<template>
  <div class="equipmentStatus">
    <div class="top">
      <div class="title">
        <div class="circle">
          <div class="circle_1"></div>
        </div>
        设备状态
      </div>
    </div>
    <div class="bottom">
      <div class="left">
        <div class="left_1">
          <div class="left_2">
            <div class="left_3">
              <div class="left_4">
                <div class="left_5">
                  <div class="left_6">
                    <div class="left_num">{{ totalDevice }}</div>
                    <div class="left_title">数量</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="right_1" v-for="(item, index) in list" :key="index">
          <div class="right_top">
            <div class="right_top_1 right_name">{{ item.name }}</div>
            <div class="right_top_1">{{ item.num }}</div>
            <div class="right_top_1">{{ item.percentage }}%</div>
          </div>
          <div class="right_bottom">
            <el-progress :percentage="item.num" :show-text="false" :color="customColor[index].color"></el-progress>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "equipmentStatus",
  data() {
    return {
      totalDevice: window.dataBoard.totalDevice,
      list: window.dataBoard.leftTop,
      // list:[
      //   {
      //     name: '开机',
      //     num: 22,
      //     percentage: 34.21,
      //   },
      //   {
      //     name: '关机',
      //     num: 2,
      //     percentage: 68.03,
      //   },
      //   {
      //     name: '故障',
      //     num: 1,
      //     percentage: 34.21,
      //   },
      //   {
      //     name: '检修',
      //     num: 1,
      //     percentage: 34.21,
      //   },
      // ],
      customColor: [
        {color: '#49B312'},
        {color: '#0eb3d0'},
        {color: '#BD352C'},
        {color: '#F59A23'},
      ],
    }
  },
}
</script>

<style scoped lang="scss">
.equipmentStatus {
  width: 100%;
  height: 100%;

  .top {
    width: 100%;
    height: 10%;
    margin-top: 2%;
    margin-left: 2%;

    .title {
      width: 20%;
      height: 100%;
      border: 1px solid rgba(2, 166, 181, 0.3);
      color: rgba(2, 166, 181, 1);
      border-radius: 0 50px 50px 0;
      font-weight: bold;
      border-left-style: none;
      display: flex;
      align-items: center;
      justify-content: center;

      .circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 1px solid rgba(2, 166, 181, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 5%;

        .circle_1 {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: rgba(2, 166, 181, 1);
          position: absolute;
        }
      }
    }
  }

  .bottom {
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-content: center;

    .left {
      width: 40%;
      height: 85%;
      margin-top: 1%;
      display: flex;
      justify-content: center;
      align-items: center;

      .left_1 {
        width: 80%;
        height: 95%;
        border-radius: 50%;
        border: 1px solid rgba(2, 166, 181, 1);
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;

        .left_2 {
          width: 98%;
          height: 100%;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-content: center;
          align-items: center;
          background: url("@/assets/image/circle.svg") no-repeat 100%;
          animation: circleRotate 8s linear infinite reverse;
          //background: url("@/assets/image/banyuan.svg") no-repeat;

          .left_3 {
            width: 80%;
            height: 80%;
            border-radius: 50%;
            border: 1px solid #02A6B5FF;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .left_4 {
              width: 85%;
              height: 85%;
              border-radius: 50%;
              border: 3px dashed #0de0f3;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              .left_5 {
                width: 99%;
                height: 99%;
                border-radius: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background: linear-gradient(to bottom, rgba(2, 166, 181, 1), rgba(11, 113, 131, 1));
                animation: circleRotate 8s linear infinite;

                .left_6 {
                  width: 85%;
                  height: 85%;
                  border: 2px dashed #0de0f3;
                  border-radius: 50%;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;

                  .left_num {
                    font-weight: bold;
                    font-size: 2rem;
                    color: #fff;
                  }

                  .left_title {
                    color: #FDDD09FF;
                    font-size: 1rem;
                    font-weight: bold;
                  }
                }
              }
            }
          }
        }
      }
    }

    @keyframes circleRotate {
      0% {
        transform: rotate3d(0, 0, 1, 0deg);
      }
      50% {
        transform: rotate3d(0, 0, 1, 180deg);
      }
      100% {
        transform: rotate3d(0, 0, 1, 360deg);
      }
    }


    .right {
      width: 60%;
      height: 85%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .right_1 {
        width: 95%;
        height: 85%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .right_top {
          //flex: 1;
          height: 45%;
          display: flex;
          justify-content: center;
          align-items: center;

          .right_top_1 {
            height: 100%;
            width: 100%;
            font-size: 1.2rem;
            font-weight: bold;
            color: #127db6;

          }

          .right_name {
            display: flex;
            justify-content: flex-start;
            color: #426374FF;
          }
        }

        .right_bottom {
          margin-top: 1%;
        }
      }
    }
  }
}
</style>
<style scoped>
/*::v-deep .el-progress-bar__outer{
  background-color: #16d3d5 !important;
}*/
</style>
