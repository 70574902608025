<template>
  <div v-if="loadEnd" class="dataBoardIndex">
    <div class="top">
      <div class="top_1">
        <div class="title">
          <router-link to="/ProductionSituation">
            设备数字监控平台
          </router-link>
        </div>

        <div class="foreign_link">
          <div style="margin-right: 20%" class="link-item"
               @click="goLink('https://mesdp.bsmyit.com/view/1633360176838995970')">
            数据统计
          </div>
          <div class="link-item" @click="goLink('https://mesdp.bsmyit.com/view/1661938247380500482')">
            数字化服务
          </div>
        </div>
      </div>
      <!--      #63ebfe-->
      <!--      数据统计：https://mesdp.bsmyit.com/view/1633360176838995970-->
      <!--      数字化服务：https://mesdp.bsmyit.com/view/1661938247380500482-->


    </div>
    <div class="bottom">
      <div class="left">
        <div class="left_1 borderBg">
          <equipment-status/>
        </div>
        <div class="left_2 borderBg">
          <device-information/>
        </div>
        <div class="left_3 borderBg">
          <fault-reporting/>
        </div>
      </div>
      <div class="center">
        <div class="center_1">
          <div class="center_1_1">
            <total-work-order/>
          </div>
          <div class="center_1_1">
            <number-of-production/>
          </div>
          <div class="center_1_1">
            <number-of-repairs/>
          </div>

        </div>
        <div class="center_2 borderBg">
          <process-progress/>
        </div>
        <div class="center_3 borderBg">
          <production-progress/>
        </div>
      </div>
      <div class="right">
        <div class="right_1 borderBg">
          <work-order-status/>
        </div>
        <div class="right_2 borderBg">
          <work-report/>
        </div>
        <div class="right_3 borderBg">
          <rejects-analyse/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EquipmentStatus from "@/views/data-board/left/equipmentStatus.vue";
import DeviceInformation from "@/views/data-board/left/deviceInformation.vue";
import FaultReporting from "@/views/data-board/left/faultReporting.vue";
import TotalWorkOrder from "@/views/data-board/center/totalWorkOrder.vue";
import NumberOfProduction from "@/views/data-board/center/numberOfProduction.vue";
import NumberOfRepairs from "@/views/data-board/center/numberOfRepairs.vue";
import ProcessProgress from "@/views/data-board/center/processProgress.vue";
import ProductionProgress from "@/views/data-board/center/productionProgress.vue";
import WorkOrderStatus from "@/views/data-board/right/workOrderStatus.vue";
import WorkReport from "@/views/data-board/right/workReport.vue";
import RejectsAnalyse from "@/views/data-board/right/rejectsAnalyse.vue";
import request from "@/utils/request";

export default {
  name: "dataBoardIndex",
  components: {
    RejectsAnalyse,
    WorkReport,
    WorkOrderStatus,
    ProductionProgress,
    ProcessProgress,
    NumberOfRepairs,
    NumberOfProduction, TotalWorkOrder, FaultReporting, DeviceInformation, EquipmentStatus
  },
  data() {
    return {
      loadEnd: false,
    }
  },
  methods: {
    goLink(link) {
      window.location.href = link;
    },
    getQueryString(name) {
      console.log('window.location.search', window.location.search)
      let reg = `(^|&)${name}=([^&]*)(&|$)`;
      let r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    getData(token, tenantId) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      request.get('/mes/chart/dataBoard', {
        params: {},
        headers: {
          'X-Access-Token': token,
          'tenant-id': tenantId
        }
      }).then(res => {
        console.log('dataBoard res', res)
        window.dataBoard = res.data.result;
        this.loadEnd = true;
        loading.close();
      })
    }
  },
  mounted() {
    let token = this.getQueryString('token');
    let tenantId = this.getQueryString('tenantId');
    console.log('token', token)
    console.log('token', token)
    this.getData(token, tenantId)
    // window.addEventListener('message', (messageEvent) => {
    //   var data = messageEvent.data;
    //   console.log('data', data)
    //   if (data.token && data.token != '') {
    //     this.getData(data.token);
    //   }
    // }, false);
  }
}
</script>

<style scoped lang="scss">
.dataBoardIndex {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .top {
    width: 100%;
    height: 10%;
    background: rgb(0, 27, 40);

    .top_1 {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: url("@/assets/image/topBg.png");
    }

    .title {
      font-size: 2rem;
      font-weight: bold;
      color: rgb(99, 235, 254);

      a {
        color: rgb(99, 235, 254);
        text-decoration: none;
      }
    }
  }

  .bottom {
    height: 90%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    background: rgb(0, 27, 40);

    .borderBg {
      border: 1px solid rgba(2, 166, 181, 0.55);
      background: linear-gradient(to left, #02a6b5, #02a6b5) left top no-repeat,
      linear-gradient(to bottom, #02a6b5, #02a6b5) left top no-repeat,
      linear-gradient(to left, #02a6b5, #02a6b5) right top no-repeat,
      linear-gradient(to bottom, #02a6b5, #02a6b5) right top no-repeat,
      linear-gradient(to left, #02a6b5, #02a6b5) left bottom no-repeat,
      linear-gradient(to bottom, #02a6b5, #02a6b5) left bottom no-repeat,
      linear-gradient(to left, #02a6b5, #02a6b5) right bottom no-repeat,
      linear-gradient(to left, #02a6b5, #02a6b5) right bottom no-repeat;
      background-size: 2px 20px, 20px 2px, 2px 20px, 20px 2px;
    }

    .left {
      width: 100%;
      height: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-left: 1%;
      margin-right: 1%;

      .left_1 {
        //flex: 1;
        width: 100%;
        height: 37%;
        margin-top: 2%;
        position: relative;

        .left_1_1 {
          position: absolute;
          height: 20%;
          border: 1px solid rgba(2, 166, 181, 0.55);
        }
      }

      .left_2 {
        //flex: 1;
        width: 100%;
        height: 50%;
        margin-top: 2%;
      }

      .left_3 {
        //flex: 1;
        width: 100%;
        height: 20%;
        margin-top: 2%;
        margin-bottom: 2%;
      }
    }

    .center {
      width: 100%;
      height: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;

      .center_1 {
        flex: 1;
        margin-top: 2%;
        display: flex;
        flex-direction: row;


        .center_1_1 {
          flex: 1;
          width: 30%;
        }
      }

      .center_2 {
        flex: 2;
        margin-top: 2%;


      }

      .center_3 {
        flex: 1;
        margin-top: 2%;
        margin-bottom: 2%;
      }
    }

    .right {
      width: 100%;
      height: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-right: 1%;
      margin-left: 1%;

      .right_1 {
        flex: 1;
        margin-top: 2%;
      }

      .right_2 {
        flex: 1;
        margin-top: 2%;
      }

      .right_3 {
        flex: 1;
        margin-top: 2%;
        margin-bottom: 2%;
      }
    }
  }
}


.foreign_link {
  //position: absolute;

  color: #63ebfe;
  position: absolute;
  right: 0;
  display: flex;
  /* right: 200px; */
  width: 35%;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 400;
  text-decoration: underline;
  top: 37px;

  .link-item {
    cursor: pointer;
  }

}


</style>
