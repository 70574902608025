<template>
  <div class="deviceInformation">
    <div class="top">
      <div class="title">
        <div class="circle">
          <div class="circle_1"></div>
        </div>
        <!--      设备信息-->
        设备预览
      </div>
    </div>
    <div class="bottom">
      <div class="bottom_1">
        <vue-seamless-scroll :data="list2" class="wrap" :class-option="defaultOption">
          <div class="bottom_sco_1">
            <div class="item" v-for="(item,index) in list2" :key="index">
              <div class="item_1">
                <div class="item_1_1">
<!--                  <el-image style="width: 100%;height: 100%" :src="item.src"></el-image>-->
                  <img style="width: 100%;height: 100%" :src="item.src"/>
                </div>
                <div class="item_1_2">
                  <div :class="item.status === 0 ? 'circle' : 'circle2'"></div>
                  {{ item.status === 0 ? '运行中' : '故障中' }}
                </div>
                <div class="item_1_3">{{ item.name }}</div>
              </div>
            </div>
          </div>
        </vue-seamless-scroll>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "deviceInformation",
  computed: {
    defaultOption() {
      return {
        step: 0.25, // 数值越大速度滚动越快
        // limitMoveNum: window.dataBoard.leftCenter.splice(6).length, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    },
  },
  data() {
    return {
      list2: window.dataBoard.leftCenter.splice(6)
      // list2: [
      //   {
      //     status: 0,
      //     name: 'XXX设备名称',
      //     src: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
      //   },
      //   {
      //     status: 0,
      //     name: 'XXX设备名称',
      //     src: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
      //   },
      //   {
      //     status: 0,
      //     name: 'XXX设备名称',
      //     src: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
      //   },
      //   {
      //     status: 1,
      //     name: 'XXX设备名称',
      //     src: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
      //   },
      //   {
      //     status: 1,
      //     name: 'XXX设备名称',
      //     src: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
      //   },
      //   {
      //     status: 1,
      //     name: 'XXX设备名称',
      //     src: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
      //   },
      // ],
    }
  },
}
</script>

<style scoped lang="scss">
.deviceInformation {
  width: 100%;
  height: 100%;

  .top {
    width: 100%;
    height: 10%;
    margin-top: 2%;
    margin-left: 2%;

    .title {
      width: 20%;
      height: 90%;
      border: 1px solid rgba(2, 166, 181, 0.3);
      color: rgba(2, 166, 181, 1);
      border-radius: 0 50px 50px 0;
      font-weight: bold;
      border-left-style: none;
      display: flex;
      align-items: center;
      justify-content: center;

      .circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 1px solid rgba(2, 166, 181, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 5%;

        .circle_1 {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: rgba(2, 166, 181, 1);
          position: absolute;
        }
      }
    }
  }

  .bottom {
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-content: center;

    .bottom_1 {
      width: 85%;
      height: 85%;
      margin-top: 2%;
      display: flex;
      flex-wrap: wrap;

      .wrap {
        width: 100%;
        height: 280px;
        overflow: hidden;
        margin: 0 auto;

        .bottom_sco_1 {
          width: 100%;
          height: 100%;
          display: flex;
          flex-wrap: wrap;

        }
      }

      .item {
        width: 45%;
        //height: 100px;
        height: 150px;
        background: #c3c3c3;
        margin-left: 5%;
        margin-bottom: 2%;

        .item_1 {
          width: 100%;
          height: 100%;
          position: relative;

          .item_1_1 {
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 0;
          }

          .item_1_2 {
            width: 80%;
            position: absolute;
            margin-top: 2%;
            margin-left: 15%;
            z-index: 1;
            color: #fff;
            display: flex;
            align-items: center;


            .circle {
              width: 5px;
              height: 5px;
              border-radius: 50%;
              border: 3px solid #45ce45;
              margin-right: 3%;
            }

            .circle2 {
              width: 5px;
              height: 5px;
              border-radius: 50%;
              border: 3px solid #a20e1a;
              margin-right: 3%;
            }
          }

          .item_1_3 {
            position: absolute;
            z-index: 1;
            background: rgba(128, 128, 128, 0.44);
            bottom: 0;
            text-align: center;
            width: 100%;
            color: #fff;
          }

        }
      }
    }
  }
}
</style>
