<template>
  <div class="productionTaskProgress">
    <div class="top">
      <div class="top_title">生产任务进度</div>
    </div>
    <div class="bottom">
      <div class="bottom_1">
        <div class="bottom_pro" v-for="(item, index) in lists" :key="index">
          <div class="pro_left">{{ item.name }}</div>
          <div class="pro_center">
            <el-progress :percentage="item.num" :stroke-width="12" :show-text="false"></el-progress>
          </div>
          <div class="pro_right">{{ item.num }}%</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "productionTaskProgress",
  data() {
    return {
      lists: window.productionSituation.center2
      // lists: [
      //   {
      //     name: 'A323',
      //     num: 80,
      //   },
      //   {
      //     name: 'A323',
      //     num: 70,
      //   },
      //   {
      //     name: 'A323',
      //     num: 60,
      //   },
      //   {
      //     name: 'A323',
      //     num: 50,
      //   },
      //   {
      //     name: 'A323',
      //     num: 40,
      //   },
      // ],
    }
  },
}
</script>

<style scoped lang="scss">
.productionTaskProgress {
  width: 100%;
  height: 100%;

  .top {
    width: 100%;
    height: 15%;
    display: flex;
    align-items: center;

    .top_title {
      width: 20%;
      height: 100%;
      margin-left: 5%;
      color: #78797a;
      font-weight: bold;
      display: flex;
      align-items: center;
      font-size: 1rem;
    }
  }

  .bottom {
    width: 100%;
    height: 85%;

    .bottom_1 {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .bottom_pro {
        width: 100%;
        height: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        justify-items: center;


        .pro_left {
          width: 15%;
          height: 100%;
          text-align: left;
          overflow: hidden;
        }

        .pro_center {
          width: 55%;
          height: 100%;
        }

        .pro_right {
          width: 10%;
          height: 100%;
          text-align: right;
        }
      }
    }
  }
}
</style>
