<template>
  <div class="faultReporting">
    <div class="top">
      <div class="title">
        <div class="circle">
          <div class="circle_1"></div>
        </div>
        故障上报情况
      </div>
    </div>
    <div class="bottom">
      <div class="bottom_1">
        <div class="bottom_title">
          <div class="title_item">上报时间</div>
          <div class="title_item">故障类型</div>
          <div class="title_item">设备类型</div>
          <div class="title_item">设备地址</div>
        </div>
        <vue-seamless-scroll :data="listData" class="warp" :class-option="defaultOption">
          <ul class="item">
            <li v-for="(item, index) in listData" :key="index">
              <span class="date">{{ item.date }}</span>
              <span class="faultType">{{ item.faultType }}</span>
              <span class="equipmentType">{{ item.equipmentType }}</span>
              <span class="address">{{ item.address }}</span>
            </li>
          </ul>
        </vue-seamless-scroll>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "faultReporting",
  computed: {
    defaultOption() {
      return {
        step: 0.15, // 数值越大速度滚动越快
        // limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    },
  },
  data() {
    return {
      listData: window.dataBoard.leftBottom
      // listData: [
      //   {
      //   date: '2022-07-31 15:25:05',
      //   faultType: '报警',
      //   equipmentType: '气感（866971866971）',
      //   address: 'XXX315号三楼二栋',
      // }, {
      //   date: '2022-07-31 15:25:05',
      //   faultType: '报警',
      //   equipmentType: '气感（866971866971）',
      //   address: 'XXX315号三楼二栋',
      // }, {
      //   date: '2022-07-31 15:25:05',
      //   faultType: '报警',
      //   equipmentType: '气感（866971866971）',
      //   address: 'XXX315号三楼二栋',
      // }, {
      //   date: '2022-07-31 15:25:05',
      //   faultType: '报警',
      //   equipmentType: '气感（866971866971）',
      //   address: 'XXX315号三楼二栋',
      // }, {
      //   date: '2022-07-31 15:25:05',
      //   faultType: '报警',
      //   equipmentType: '气感（866971866971）',
      //   address: 'XXX315号三楼二栋',
      // }, {
      //   date: '2022-07-31 15:25:05',
      //   faultType: '报警',
      //   equipmentType: '气感（866971866971）',
      //   address: 'XXX315号三楼二栋',
      // },
      // ],
    }
  },
}
</script>

<style scoped lang="scss">
.faultReporting {
  width: 100%;
  height: 100%;

  .top {
    width: 100%;
    height: 15%;
    margin-top: 2%;
    margin-left: 2%;

    .title {
      width: 25%;
      height: 100%;
      border: 1px solid rgba(2, 166, 181, 0.3);
      color: rgba(2, 166, 181, 1);
      border-radius: 0 50px 50px 0;
      font-weight: bold;
      border-left-style: none;
      display: flex;
      align-items: center;
      justify-content: center;

      .circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 1px solid rgba(2, 166, 181, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 5%;

        .circle_1 {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: rgba(2, 166, 181, 1);
          position: absolute;
        }
      }
    }
  }

  .bottom {
    width: 100%;
    height: 85%;
    display: flex;
    align-items: center;
    justify-content: center;

    .bottom_1 {
      margin-top: 2%;
      width: 90%;
      height: 90%;

      .bottom_title {
        width: 100%;
        height: 10%;
        display: flex;
        justify-items: center;
        align-items: center;

        .title_item {
          flex: 1;
          color: #fff;
          font-size: 0.5rem;
          font-weight: bold;
        }
      }

      .warp {
        height: 80px;
        width: 100%;
        margin-top: 2%;
        overflow: hidden;

        ul {
          list-style: none;
          padding: 0;
          margin: 0 auto;

          li {
            display: block;
            height: 30px;
            line-height: 30px;
            display: flex;
            justify-content: space-between;
            font-size: 0.5rem;
            color: #fff;

            span {
              flex: 1;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            span:hover {
              text-overflow: inherit;
              overflow: visible;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}
</style>
