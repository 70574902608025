<template>
  <div class="productionProgress">
    <div class="top">
      <div class="title">
        <div class="circle">
          <div class="circle_1"></div>
        </div>
        生产进度
      </div>
    </div>
    <div class="bottom">
      <div id="echarts" style="width: 100%;height: 100%;"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "productionProgress",
  data() {
    return {
      colorList: [
        ['#62a8fd', '#09e9f3', '#68c5ac'],
        ['#8d2efe', '#c7c6dc'],
        ['#f7fc07', '#f2f5a8'],
        ['#fe9834', '#e1a161'],
      ],
      option: {
        title: {},
        legend: {
          bottom: '5%',
          itemHeight: 6,
          itemWidth: 8,
          textStyle: {
            fontSize: 12,
            color: "#fff",
          },
        },
        tooltip: {
          trigger: 'axis',
          //亩
          valueFormatter: (value) => (value ? value + '' : '-'),
        },
        grid: {
          left: '5%',
          right: '10%',
          bottom: '20%',
          top: '20%',
          containLabel: true,
        },
        dataset: {
          dimensions: [],
          source: [],
        },
        xAxis: {
          name: '日期',
          type: 'category',
        },
        yAxis: {
          ///亩
          //单位
          name: '',
          minInterval: 4,
          axisLabel: {
            show: true,
            // %
            formatter: '{value}'//以百分比显示
          },
        },
        series: [],
      },
    }
  },
  methods: {
    getOption() {
      let data = [
        // {
        //   month: '12-01',
        //   name: '生产进度',
        //   count: 1.2,
        // },
        // {
        //   month: '12-02',
        //   name: '生产进度',
        //   count: 3,
        // },
        // {
        //   month: '12-03',
        //   name: '生产进度',
        //   count: 1.5,
        // },
        // {
        //   month: '12-04',
        //   name: '生产进度',
        //   count: 1.2,
        // },
        // {
        //   month: '12-05',
        //   name: '生产进度',
        //   count: 8,
        // },{
        //   month: '12-06',
        //   name: '生产进度',
        //   count: 10,
        // },{
        //   month: '12-07',
        //   name: '生产进度',
        //   count: 12,
        // },{
        //   month: '12-08',
        //   name: '生产进度',
        //   count: 15,
        // },

      ];
      data = window.dataBoard.centerBottom
      let dataset1 = {
        dimensions: [],
        source: [],
      };
      let source = [];
      if (data !== null) {
        let names = [];

        data.forEach((item) => {
          let source2 = []
          let name = item.name;
          if (names.indexOf(name) === -1) {
            names.push(name);
          }
          source2.push(item.month)
          source2.push(item.count)
          source.push(source2)
        });
        dataset1.dimensions = ['product', ...names];
        dataset1.source = [...source];
        this.option.dataset = dataset1;
        let tempSeries1 = this.getMultiBars(names, this.colorList);
        this.option.series = [...tempSeries1];
      } else {
        this.option.dataset = [];
      }
    },
    getMultiBars(legend, colorList) {
      let size = colorList.length;
      let series = [];
      for (let i = 0; i < legend.length; i++) {
        let colors = [];
        let tempColor = colorList[i % size];
        for (let j = 0; j < tempColor.length; j++) {
          colors.push({
            offset: j / (tempColor.length - 1),
            color: tempColor[j],
          });
        }
        series.push({
          type: 'bar',
          name: legend[i],
          itemStyle: {
            normal: {
              //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
              color: new this.echarts.graphic.LinearGradient(0, 0, 0, 1, colors),
              //设置柱子圆角
              barBorderRadius: [3, 3, 3, 3],
            },
          },
          emphasis: {
            //鼠标放在柱子上显示的效果
            itemStyle: {
              shadowBlur: 10,
              shadowColor: 'rgba(0,0,0,0.3)',
            },
          },
          barWidth: 15,
        });
      }
      return series;
    },
    getEcharts() {
      this.$nextTick(() => {
        let myChart = this.echarts.init(document.getElementById('echarts'));
        myChart.setOption(this.option);
        // 图表自适应
        window.addEventListener('resize', () => {
          setTimeout(() => {
            myChart.resize();
          }, 100)
        })
        /*window.onresize = function(){
          myChart.resize();
        }*/
      })
    },
  },
  created() {
    this.getOption();
    this.getEcharts()
  },
}
</script>

<style scoped lang="scss">
.productionProgress {
  width: 100%;
  height: 100%;

  .top {
    width: 100%;
    height: 15%;
    margin-top: 2%;
    margin-left: 2%;

    .title {
      width: 20%;
      height: 100%;
      border: 1px solid rgba(2, 166, 181, 0.3);
      color: rgba(2, 166, 181, 1);
      border-radius: 0 50px 50px 0;
      font-weight: bold;
      border-left-style: none;
      display: flex;
      align-items: center;
      justify-content: center;

      .circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 1px solid rgba(2, 166, 181, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 5%;

        .circle_1 {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: rgba(2, 166, 181, 1);
          position: absolute;
        }
      }
    }
  }

  .bottom {
    width: 100%;
    height: 90%;
  }
}
</style>
