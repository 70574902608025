<template>
  <div v-if="loadEnd" class="productionSituation">
    <div class="top">
      <quantity-rate/>
    </div>
    <div class="center">
      <div class="center_1">
        <equipment-status-pie/>
      </div>
      <div class="center_2">
        <production-task-progress/>
      </div>
      <div class="center_3">
        <good-products/>
      </div>
    </div>
    <div class="bottom">
      <product-production-trend/>
    </div>
  </div>
</template>

<script>
import QuantityRate from "@/views/production-situation/top/quantityRate.vue";
import EquipmentStatusPie from "@/views/production-situation/center/equipmentStatusPie.vue";
import ProductionTaskProgress from "@/views/production-situation/center/productionTaskProgress.vue";
import GoodProducts from "@/views/production-situation/center/goodProducts.vue";
import ProductProductionTrend from "@/views/production-situation/right/productProductionTrend.vue";

import request from '@/utils/request.js'


export default {
  name: "productionSituation",
  components: {ProductProductionTrend, GoodProducts, ProductionTaskProgress, EquipmentStatusPie, QuantityRate},
  data() {
    return {
      loadEnd: false,
    }
  },
  methods: {
    getQueryString(name) {
      let reg = `(^|&)${name}=([^&]*)(&|$)`;
      let r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    getData(token, tenantId) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      request.get('/mes/chart/productionSituation', {
        params: {},
        headers: {
          'X-Access-Token': token,
          'tenant-id': tenantId
        }
      }).then(res => {
        console.log('productionSituation res', res)
        window.productionSituation = res.data.result;
        this.loadEnd = true;
        loading.close();
      })
    }
  },
  mounted() {
    // this.getData(this.getQueryString('token'))
    window.addEventListener('message', (messageEvent) => {
      var data = messageEvent.data;
      console.log('data', data)
      if (data.token && data.token != '') {
        this.getData(data.token, data.tenantId);
      }
    }, false);
  }
}
</script>

<style scoped lang="scss">
.productionSituation {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: rgb(240, 242, 245);
  white-space: nowrap;

  .top {
    flex: 1;
    height: 98%;
    width: 98%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .center {
    flex: 2;
    height: 98%;
    width: 96%;
    display: flex;
    justify-content: center;
    align-items: center;

    .center_1 {
      flex: 1;
      height: 95%;
      background: #fff;
      border-radius: 10px;
      box-shadow: 0 1px 5px 1px #b0acac;
    }

    .center_2 {
      flex: 1;
      height: 95%;
      margin: 0 2%;
      background: #fff;
      border-radius: 10px;
      box-shadow: 0 1px 5px 1px #b0acac;
    }

    .center_3 {
      flex: 1;
      height: 95%;
      background: #fff;
      border-radius: 10px;
      box-shadow: 0 1px 5px 1px #b0acac;
    }

  }

  .bottom {
    flex: 3;
    height: 98%;
    width: 96%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 1px 5px 1px #b0acac;
    margin-bottom: 0.5%;
    margin-top: 0.5%;
  }
}
</style>
